<template>
  <v-container fluid>
    <v-row class="mt-1">
      <v-col>
        <span class="font-weight-bold">
          {{$route.meta.headerTitle}}
        </span>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-tabs
          v-model="activeTab"
          background-color="transparent"
        >
          <v-tab class="font-weight-bold text-capitalize">
            {{$_strings.companyInformation.TAB_NAME}}
          </v-tab>
          <v-tab class="font-weight-bold text-capitalize">
            {{$_strings.companyDocuments.TAB_NAME}}
          </v-tab>
          <v-tab class="font-weight-bold text-capitalize">
            {{$_strings.companyRegistration.PAGE_NAME}}
          </v-tab>
        </v-tabs>
      </v-col>
    </v-row>
    <v-card
      elevation="5"
      tile
    >
      <v-tabs-items v-model="activeTab">
        <v-tab-item>
          <v-card-title class="font-weight-bold">
            {{$_strings.companyInformation.TAB_NAME}}
          </v-card-title>
          <CompanyInformation
            :form="form"
            :isViewOnly="true"
          />
        </v-tab-item>
        <v-tab-item>
          <v-card-title class="font-weight-bold">
            {{$_strings.companyDocuments.TAB_NAME}}
          </v-card-title>
          <CompanyDocuments
            :form="form"
          />
        </v-tab-item>
        <v-tab-item>
          <Approval
            :workflowData="workflowData"
          />
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </v-container>
</template>

<script>
import CompanyInformation from '../components/CompanyRegistration/CompanyInformation';
import CompanyDocuments from '../components/CompanyRegistration/CompanyDocuments';
import Approval from '../components/CompanyRegistration/Approval';

export default {
  name: 'CompanyRegistrationPage',
  components: {
    CompanyInformation,
    CompanyDocuments,
    Approval,
  },
  data() {
    return {
      activeTab: +this.$route.query.tab || 0,
      workflowData: {},
      form: {
        name: '',
        abbreviation: '',
        address: {
          address1: '',
          address2: '',
          urbanVillage: '',
          provinceId: '',
          cityId: '',
          districtId: '',
          subDistrictId: '',
          postalCode: '',
        },
        billingAddress: {
          address1: '',
          address2: '',
          urbanVillage: '',
          provinceId: '',
          cityId: '',
          districtId: '',
          subDistrictId: '',
          postalCode: '',
        },
        npwpNo: '',
        npwpAddress: '',
        pkpNo: '',
        presidentDirectorSalutation: '',
        presidentDirector: '',
        jobTitle: '',
        siupNo: '',
        tdpNo: '',
        email: '',
        phoneNo: '',
        faxNo: '',
        companyService: '',
        establishDate: '',
        notes: '',
        documentSupports: [],
      },
      historyAction: [],
    };
  },
  methods: {
    services() {
      const { defWorkflow } = this.$route.params;
      switch (+defWorkflow) {
        case 1: return this.$_services.companyRegistration.getShipperRegistrationByWorkflowId;
        case 2: return this.$_services.companyRegistration.getTransporterRegistrationByWorkflowId;
        case 15: return this.$_services.approval.getWorkflow;
        default:
          break;
      }
    },
    setData(data) {
      this.workflowData = data;
      this.form = data.data;
    },
    async fetchWorkFlowData() {
      const { id } = this.$route.params;
      this.$root.$loading.show();
      try {
        const result = await this.services()(id);
        const { data } = result;
        this.setData(data);
      } finally {
        this.$root.$loading.hide();
      }
    },
  },
  created() {
    const { data } = this.$route.params;
    if (data) {
      this.setData(data);
    } else {
      this.fetchWorkFlowData();
    }
  },
};
</script>

<style>
</style>

<template>
  <v-container fluid>
    <v-data-table
      :headers="displayedHeader"
      :items="form.documentSupports"
      item-key="documentId"
      :items-per-page="100"
      hide-default-footer
    >
      <template v-slot:[`item.no`]="{index}">
        <span>
          {{index + 1}}
        </span>
      </template>
      <template v-slot:[`item.documentName`]="{item, index}">
        <a
          v-if="item.url"
          @click="openDocument(item, index)"
        >
          {{item.documentInformation.descriptions}}
          <viewer-image
            :ref="'viewer_image'+index"
            :key="item.url"
            :src="item.url"
            class="d-none"
          />
        </a>
        <span v-else>
          {{item.documentInformation.descriptions}}
        </span>
      </template>
      <template v-slot:[`item.documentInformation.isMandatory`]="{item}">
        <span>
          {{item.documentInformation.isMandatory ? $_strings.common.YES : $_strings.common.NO}}
        </span>
      </template>
      <template v-slot:[`item.documentInformation.descriptions`]="{item}">
        <span v-if="item.url">
          1 dari 1 File
        </span>
        <span v-else>
          0 dari 1 File
        </span>
      </template>
    </v-data-table>
    <v-row class="mt-2">
      <v-col class="grey--text text--darken-1 caption">
        <span>Legend:</span>
        <p class="ma-0">Dokumen format jpeg, pdf, png</p>
        <p class="ma-0">Size maksimal 1 MB</p>
        <p class="ma-0">Akta maksimal 7 MB</p>
      </v-col>
    </v-row>
    <dialog-view-pdf :visible="dialogViewPdf" @setDialog="setDialog" :urlPdf="urlPdfSelected"/>
  </v-container>
</template>

<script>
export default {
  name: 'CompanyDocuments',
  props: {
    form: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      items: [],
      dialogViewImage: false,
      dialogViewPdf: false,
      urlPdfSelected: '',
      headers: [
        {
          text: this.$_strings.companyDocuments.NO_TABLE_HEADER_LABEL,
          value: 'no',
          cellClass: 'w-10',
        },
        {
          text: this.$_strings.companyDocuments.NAME_TABLE_HEADER_LABEL,
          value: 'documentName',
        },
        {
          text: this.$_strings.companyDocuments.MANDATORY_TABLE_HEADER_LABEL,
          value: 'documentInformation.isMandatory',
          cellClass: 'w-20',
        },
        {
          text: this.$_strings.companyDocuments.DESCRIPTION_TABLE_HEADER_LABEL,
          value: 'documentInformation.descriptions',
          cellClass: 'w-20',
        },
      ],
    };
  },
  computed: {
    displayedHeader() {
      return this.headers.map((i) => ({
        ...i,
        class: 'white--text primary text-capitalize',
        // cellClass: 'clickable',
      }));
    },
  },
  methods: {
    setDialog(dialog) {
      this[dialog] = !this[dialog];
    },
    openDocument(item, index) {
      const fileType = (item.documentName && item.documentName.split('.').pop().toLowerCase()) || '';
      if (fileType === 'png' || fileType === 'jpg' || fileType === 'jpeg') {
        this.$refs[`viewer_image${index}`].$el.children[0].click();
        return;
      }
      if (fileType === 'pdf') {
        this.dialogViewPdf = true;
        this.urlPdfSelected = item.url;
        return;
      }
      window.open(item.url);
    },
  },
};
</script>

<style>
</style>

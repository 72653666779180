<template>
  <v-container fluid>
    <v-form
      ref="form"
      lazy-validation
    >
      <common-text-input
        :inputLabel="$_strings.companyInformation.COMPANY_NAME_INPUT_LABEL"
        v-model="form.name"
        :readOnly=isViewOnly
      />
      <common-text-input
        :inputLabel="$_strings.companyInformation.ALIAS_INPUT_LABEL"
        v-model="form.abbreviation"
        :readOnly=isViewOnly
      />
      <common-text-input
        :inputLabel="$_strings.companyInformation.COMPANY_ADDRESS_INPUT_LABEL"
        v-model="form.address.address1"
        isTextArea
        :readOnly=isViewOnly
      />
      <v-row no-gutters>
        <v-col offset-md="2">
          <common-autocomplete-input
            :inputLabel="$_strings.locations.PROVINCE_INPUT_LABEL"
            v-model="form.address.provinceId"
            :label="$_strings.locations.PROVINCE_INPUT_LABEL"
            item-text="text"
            item-value="value"
            :items="provinceList"
            :readOnly=isViewOnly
          />
        </v-col>
        <v-col>
          <common-autocomplete-input
            :inputLabel="$_strings.locations.CITY_INPUT_LABEL"
            v-model="form.address.cityId"
            :label="$_strings.locations.CITY_INPUT_LABEL"
            item-text="text"
            item-value="value"
            :items="cityList"
            :readOnly=isViewOnly
          />
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col offset-md="2">
          <common-autocomplete-input
            :inputLabel="$_strings.locations.DISTRICT_INPUT_LABEL"
            v-model="form.address.districtId"
            :label="$_strings.locations.DISTRICT_INPUT_LABEL"
            item-text="text"
            item-value="value"
            :items="districtList"
            :readOnly=isViewOnly
          />
        </v-col>
        <v-col>
          <common-autocomplete-input
            :inputLabel="$_strings.locations.SUBDISTRICT_INPUT_LABEL"
            v-model="form.address.subDistrictId"
            :label="$_strings.locations.SUBDISTRICT_INPUT_LABEL"
            item-text="text"
            item-value="value"
            :items="subDistrictList"
            :readOnly=isViewOnly
          />
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col
          offset-md="2"
          cols="5"
        >
          <common-text-input
            :inputLabel="$_strings.locations.POSTAL_CODE_INPUT_LABEL"
            v-model="form.address.postalCode"
            :labelWidth="3"
            :readOnly=isViewOnly
          />
        </v-col>
      </v-row>
      <v-row
        no-gutters
        class="ml-3"
      >
        <v-col
          cols="auto"
          :md="2"
          class="mt-1"
        >
          <span class="body-2">
            {{$_strings.companyInformation.BILLING_ADDRESS_INPUT_LABEL}}
          </span>
        </v-col>
        <v-col class="mt-n3">
          <v-checkbox
            :disabled=isViewOnly
            v-model="billingAddressSameAsCompanyAddress"
            :label="$_strings.companyInformation.SAME_AS_COMPANY_ADDRESS_CHECKBOX_LABEL"
          ></v-checkbox>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col offset-md="2">
          <common-text-input
            :inputLabel="$_strings.companyInformation.BILLING_ADDRESS_INPUT_LABEL"
            hideLabel
            v-model="form.billingAddress.address1"
            isTextArea
            :readOnly=isViewOnly

          />
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col offset-md="2">
          <common-autocomplete-input
            :inputLabel="$_strings.locations.PROVINCE_INPUT_LABEL"
            v-model="form.billingAddress.provinceId"
            :label="$_strings.locations.PROVINCE_INPUT_LABEL"
            item-text="text"
            item-value="value"
            :items="provinceList"
            :readOnly=isViewOnly
          />
        </v-col>
        <v-col>
          <common-autocomplete-input
            :inputLabel="$_strings.locations.CITY_INPUT_LABEL"
            v-model="form.billingAddress.cityId"
            :label="$_strings.locations.CITY_INPUT_LABEL"
            item-text="text"
            item-value="value"
            :items="cityListBillingAddress"
            :readOnly=isViewOnly
          />
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col offset-md="2">
          <common-autocomplete-input
            :inputLabel="$_strings.locations.DISTRICT_INPUT_LABEL"
            v-model="form.billingAddress.districtId"
            :label="$_strings.locations.DISTRICT_INPUT_LABEL"
            item-text="text"
            item-value="value"
            :items="districtListBillingAddress"
            :readOnly=isViewOnly
          />
        </v-col>
        <v-col>
          <common-autocomplete-input
            :inputLabel="$_strings.locations.SUBDISTRICT_INPUT_LABEL"
            v-model="form.billingAddress.subDistrictId"
            :label="$_strings.locations.SUBDISTRICT_INPUT_LABEL"
            item-text="text"
            item-value="value"
            :items="subDistrictListBillingAddress"
            :readOnly=isViewOnly
          />
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col
          offset-md="2"
          cols="5"
        >
          <common-text-input
            :inputLabel="$_strings.locations.POSTAL_CODE_INPUT_LABEL"
            v-model="form.billingAddress.postalCode"
            :labelWidth="3"
            :readOnly=isViewOnly
          />
        </v-col>
      </v-row>
      <common-text-input
        :inputLabel="$_strings.companyInformation.NPWP_INPUT_LABEL"
        v-model="form.npwpNo"
        :readOnly=isViewOnly
      />
      <common-text-input
        :inputLabel="$_strings.companyInformation.NPWP_ADDRESS_INPUT_LABEL"
        v-model="form.npwpAddress"
        isTextArea
        :readOnly=isViewOnly
      />
      <common-text-input
        :inputLabel="$_strings.companyInformation.PKP_NO_INPUT_LABEL"
        v-model="form.pkpNo"
        isTextArea
        :readOnly=isViewOnly
      />
      <v-col>
        <v-row>
          <v-col cols="2 pt-0">
            <p class="body-2 mt-2">
              {{$_strings.companyInformation.COMPANY_PRESIDENT_NAME_INPUT_LABEL}}
            </p>
          </v-col>
          <v-col cols="2 pt-0">
            <v-select
              hide-details
              outlined
              dense
              v-model="form.presidentDirectorSalutation"
              :items="['Bpk', 'Ibu']"
              :disabled=isViewOnly
            ></v-select>
          </v-col>
          <v-col cols="ma-0 pa-0">
            <common-text-input
              v-model="form.presidentDirector"
              :readOnly=isViewOnly
            />
          </v-col>
        </v-row>
      </v-col>
      <common-text-input
        :inputLabel="$_strings.companyInformation.POSITION_INPUT_LABEL"
        v-model="form.jobTitle"
        :readOnly=isViewOnly
      />
      <common-text-input
        :inputLabel="$_strings.companyInformation.SIUP_NO_INPUT_LABEL"
        v-model="form.siupNo"
        :readOnly=isViewOnly
      />
      <common-text-input
        :inputLabel="$_strings.companyInformation.TDP_NO_INPUT_LABEL"
        v-model="form.tdpNo"
        :readOnly=isViewOnly
      />
      <common-text-input
        :inputLabel="$_strings.companyInformation.COMPANY_EMAIL_INPUT_LABEL"
        v-model="form.email"
        :readOnly=isViewOnly
      />
      <v-row no-gutters>
        <v-col
          :cols="12"
          :md="2"
          class="pl-3 mt-2"
        >
          <span class="body-2">
            {{$_strings.companyInformation.COMPANY_PHONE_NO_INPUT_LABEL}}
          </span>
        </v-col>
        <v-col>
          <common-text-input
            :inputLabel="$_strings.companyInformation.OFFICE_INPUT_LABEL"
            v-model="form.phoneNo"
            :readOnly=isViewOnly
          />
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col offset-md="2">
          <common-text-input
            :inputLabel="$_strings.companyInformation.FAX_INPUT_LABEL"
            v-model="form.faxNo"
            :readOnly=isViewOnly
          />
        </v-col>
      </v-row>
      <common-text-input
        :inputLabel="$_strings.companyInformation.COMPANY_SERVICE_INPUT_LABEL"
        v-model="form.companyService"
        :readOnly=isViewOnly
      />
      <common-date-input
        :inputLabel="$_strings.companyInformation.COMPANY_ESTABLISHED_YEAR_INPUT_LABEL"
        v-model="form.establishDate"
        :readOnly=isViewOnly
      />
      <v-container fluid>
        <v-row class="my-n6">
          <v-col
            cols="12"
            md="2"
          >
            <span class="body-2">
              {{$_strings.companyInformation.COMPANY_ADDITIONAL_INFO_INPUT_LABEL}}
            </span>
          </v-col>
          <v-col cols="12" md="10">
            <v-textarea
              no-resize
              outlined
              :placeholder="$_strings.companyInformation.COMPANY_ADDITIONAL_INFO_INPUT_LABEL"
              :disabled="isViewOnly"
              dense
              v-model="form.notes"
              clearable
              rows="5"
            ></v-textarea>
          </v-col>
        </v-row>
      </v-container>
    </v-form>
  </v-container>
</template>

<script>
export default {
  name: 'CompanyInformation',
  props: {
    form: {
      type: Object,
      default: () => ({}),
    },
    isViewOnly: {
      type: Boolean,
    },
  },
  data() {
    return {
      provinceList: [],
      cityList: [],
      cityBillingAddressLoading: false,
      cityListBillingAddress: [],
      districtList: [],
      districtListBillingAddress: [],
      subDistrictList: [],
      subDistrictListBillingAddress: [],
    };
  },
  computed: {
    billingAddressSameAsCompanyAddress: {
      get() {
        return (
          (this.form.address.address1 === this.form.billingAddress.address1)
          && (this.form.address.subDistrictId === this.form.billingAddress.subDistrictId)
          && (this.form.address.districtId === this.form.billingAddress.districtId)
          && (this.form.address.cityId === this.form.billingAddress.cityId)
          && (this.form.address.provinceId === this.form.billingAddress.provinceId)
          && (this.form.address.postalCode === this.form.billingAddress.postalCode)
        );
      },
      set(bool) {
        if (bool) {
          this.form.billingAddress.address1 = this.form.address.address1;
          this.form.billingAddress.subDistrictId = this.form.address.subDistrictId;
          this.form.billingAddress.districtId = this.form.address.districtId;
          this.form.billingAddress.cityId = this.form.address.cityId;
          this.form.billingAddress.provinceId = this.form.address.provinceId;
          this.form.billingAddress.postalCode = this.form.address.postalCode;
        } else {
          this.form.billingAddress.address1 = null;
          this.form.billingAddress.subDistrictId = null;
          this.form.billingAddress.districtId = null;
          this.form.billingAddress.cityId = null;
          this.form.billingAddress.provinceId = null;
          this.form.billingAddress.postalCode = null;
        }
      },
    },
  },
  methods: {
    setSelectListItems() {
      const { address, billingAddress } = this.form;
      this.provinceList = [{
        text: address.province,
        value: address.provinceId,
      }];
      this.cityList = [{
        text: address.city,
        value: address.cityId,
      }];
      this.districtList = [{
        text: address.district,
        value: address.districtId,
      }];
      this.subDistrictList = [{
        text: address.subDistrict,
        value: address.subDistrictId,
      }];
      // Billing Address
      this.provinceListBillingAddress = [{
        text: billingAddress.province,
        value: billingAddress.provinceId,
      }];
      this.cityListBillingAddress = [{
        text: billingAddress.city,
        value: billingAddress.cityId,
      }];
      this.districtListBillingAddress = [{
        text: billingAddress.district,
        value: billingAddress.districtId,
      }];
      this.subDistrictListBillingAddress = [{
        text: billingAddress.subDistrict,
        value: billingAddress.subDistrictId,
      }];
    },
  },
  mounted() {
    this.setSelectListItems();
  },
};
</script>

<style>
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-data-table',{attrs:{"headers":_vm.displayedHeader,"items":_vm.form.documentSupports,"item-key":"documentId","items-per-page":100,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.no",fn:function(ref){
var index = ref.index;
return [_c('span',[_vm._v(" "+_vm._s(index + 1)+" ")])]}},{key:"item.documentName",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(item.url)?_c('a',{on:{"click":function($event){return _vm.openDocument(item, index)}}},[_vm._v(" "+_vm._s(item.documentInformation.descriptions)+" "),_c('viewer-image',{key:item.url,ref:'viewer_image'+index,staticClass:"d-none",attrs:{"src":item.url}})],1):_c('span',[_vm._v(" "+_vm._s(item.documentInformation.descriptions)+" ")])]}},{key:"item.documentInformation.isMandatory",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.documentInformation.isMandatory ? _vm.$_strings.common.YES : _vm.$_strings.common.NO)+" ")])]}},{key:"item.documentInformation.descriptions",fn:function(ref){
var item = ref.item;
return [(item.url)?_c('span',[_vm._v(" 1 dari 1 File ")]):_c('span',[_vm._v(" 0 dari 1 File ")])]}}],null,true)}),_c('v-row',{staticClass:"mt-2"},[_c('v-col',{staticClass:"grey--text text--darken-1 caption"},[_c('span',[_vm._v("Legend:")]),_c('p',{staticClass:"ma-0"},[_vm._v("Dokumen format jpeg, pdf, png")]),_c('p',{staticClass:"ma-0"},[_vm._v("Size maksimal 1 MB")]),_c('p',{staticClass:"ma-0"},[_vm._v("Akta maksimal 7 MB")])])],1),_c('dialog-view-pdf',{attrs:{"visible":_vm.dialogViewPdf,"urlPdf":_vm.urlPdfSelected},on:{"setDialog":_vm.setDialog}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }